.profile {
    background: #F9FAFB;
    height: 100%;
    padding: 20px;
}
.logo-profile {
    width: 200px;
    height: auto;
    margin: 0 auto;
    display: block;
}
.profile a img {
    width: 10px;
    height: auto;
    margin-right: 4px;
  }
  .profile a {
      text-decoration: none;
      color: #000;
      padding: 5px;
      background: #fff;
      border-radius: 8px;
      box-shadow: 1px 1px 3px 0px #0000001A;
      font-family: 'Helvetica', sans-serif;
      font-size: 9px;
      font-weight: 750;
      text-align: center;
      margin-top: 20px;
      margin-left: 20px;
  }
.container-profile {
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding: 40px;
    justify-content: center;
}
.card-profile {
    width: 600px;
    background: #FFFFFF;
    box-shadow: 0px 1px 2px 0px #0000000F, 0px 1px 3px 0px #0000001A;   
    border-radius: 10px; 
}
.card-profile h1 {
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid #BBBBBB;
    font-family: 'Segoe UI', sans-serif;
    font-size: 32px;
    font-weight: 600;
    line-height: 36px;
    text-align: left;
    color: #333;
}
.profile-content {
   padding: 20px;
   width: 100%;
   display: flex;
   height: 100%;
   flex-direction: column;
}
.profile-content h3, .profile-content span, .profile-content p, .profile-content button {
    margin-top: 10px;
}
.profile-content h3 {
    font-family: 'Segoe UI', sans-serif;
    font-size: 25px;
    font-weight: 600;
    text-align: left;
    color: #333;
    text-transform: capitalize;
}
.profile-content span {
    font-family: 'Segoe UI', sans-serif;
    font-size: 16px;
    font-weight: 300;
    text-align: left;
    color: #333;
}
.profile-content p {
    font-family: 'Segoe UI', sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    color: #333;
}
.profile-content span a {
    color: #333;
}
.profile-content button {
    padding: 9px;
    border-radius: 9px;
    outline: none;
    border: none;
    cursor: pointer;
    font-family: 'Helvetica', sans-serif;
    font-size: 21px;
    font-weight: 700;
    text-align: center;
    color: #fff;
    background: linear-gradient(180deg, #FF6F48 0%, #FF915A 100%);
}
.red-profile {
    background: #f95959 !important; 
}
.profile-banner, .email-reset, .reset-pw {
    width: 720px;
    box-shadow: 0px 1px 2px 0px #0000000F, 0px 1px 3px 0px #0000001A;
    background: #FFFFFF;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: none;
    z-index: 1000;
}
.email-reset form, .reset-pw form {
    width: 80%;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin: 0 auto;
}
.email-reset form input, .email-reset form button, .reset-pw form input, .reset-pw form button {
  width: 100%;
}
.email-reset form input, .reset-pw form input {
    border: 1px solid #0F1519;
    outline: none;
    padding: 10px;
    border-radius: 10px;
    margin-top: 10px;
    background: #FE805026;
}
.email-reset form button, .reset-pw form button {
    color: #fff;
    background: linear-gradient(180deg, #FF6F48 0%, #FF915A 100%);
    height: 50px;
    border-radius: 25px;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    border: none;
    text-decoration: none;
}
.profile-banner.open, .email-reset.open, .reset-pw.open {
    display: block;
}
.profile-banner ul {
    width: 100%;
    height: 100%;
}
.profile-banner ul li {
    list-style: none;
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #CCCCCC;
    font-family: 'DM Sans', sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    cursor: pointer;
}
.profile-banner ul li:hover {
    background: linear-gradient(180deg, #FF6F48 0%, #FF915A 100%);
    color: #fff;
}
.profile-banner ul li:nth-child(6) {
    border: none;
}

.payment-form {
    z-index: 12;
    width: 416px;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    padding: 10px;
}
.payment-form h4 {
    font-family: 'Roboto', sans-serif;
    font-size: 22px;
    font-weight: 500;
    text-align: left;
    color: #333;
    margin-bottom: 15px;
}
.payment-form label {
    color: #333;
    margin-top: 15px;
    margin-bottom: 5px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
}
.card-payment {
    width: 720px;
    box-shadow: 0px 1px 2px 0px #0000000F, 0px 1px 3px 0px #0000001A;
    background: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: none;
    z-index: 1000;
    align-items: flex-start;
    justify-content: space-between;
    height: 240px;
}
.card-payment.open {
    display: flex;
}

.container-left-content {
    width: 40%;
    height: 100%;
}
.container-left-content {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, #FF6F48 0%, #FF915A 100%);
}
.container-left-content .Billing-img {
    margin-top: 0 !important;
    width: 50%;
    height: 50%;
}
.payment-form button {
    font-family: 'Roboto', sans-serif;
    font-size: 17px;
    font-weight: 500;
    text-align: center;
    color: #fff;
    cursor: pointer;
    outline: none;
    background: linear-gradient(180deg, #FF6F48 0%, #FF915A 100%);
    border: none;
    z-index: 10;
    width: 100%;
    height: 48px;
    margin-top: 10px;
    border-radius: 20px;
}
.payment-form p {
    color: #333;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    text-align: left;
    font-size: 12px !important;
    width: 100%;
    margin-top: 10px;
}

@media(max-width: 768px) {
    .card-profile {
        width: 100%;
    }
    .container-profile {
        padding: 0;
        margin-top: 20px;
    }
    .profile-banner {
        width: 90%;
    }
    .card-payment {
        width: 90%;
        flex-direction: column;
        height: auto;
    }
    .payment-form {
        width: 100%;
    }
    .container-left-content {
        width: 100%;
        height: 100%;
    }
}