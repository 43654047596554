.contact {
    background: #F9FAFB;
}

.container-contact {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    padding-top: 40px;
    position: relative;
}
.container-contact img {
    width: 40%;
}
.container-contact .contact-form {
    width: 40%;
    background: #fff;
    box-shadow: 0px 7px 30px 0px #1D1D1F0D;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 40px;
    border-radius: 10px;
    z-index: 99;
}
.container-contact  .effect-contact {
    position: absolute;
    bottom: 80px;
    right: 40px;
    z-index: 1;
    width: 100px;
}
.container-contact .contact-form h1 {
    font-family: 'Inter', sans-serif;
    font-size: 48px;
    font-weight: 600;
    text-align: center;
    color: #1D1D1F;
}
.container-contact .contact-form .input-field{
    position: relative;
    width: 80%;
    margin: 20px auto;
  }
.container-contact .contact-form  .input-field input, .container-contact .contact-form  .input-field textarea{
    padding: 10px;
    border-radius: 6px;
    font-size: 18px;
    border: 2px solid #1D1D1F;
    background: transparent;
    color: #1D1D1F;
    outline: none;
    width: 100%;
  }
.container-contact .contact-form  .input-field label{
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    color: #1D1D1F;
    font-size: 19px;
    pointer-events: none;
    transition: 0.3s;
    font-family: 'Segoe UI', sans-serif;
    font-size: 16px;
    font-weight: 400;
    text-align: left;

  }
 .container-contact .contact-form input:focus, .container-contact .contact-form textarea:focus{
    border: 2px solid #1D1D1F;
  }
.container-contact .contact-form  input:focus ~ label,
.container-contact .contact-form  input:valid ~ label
 {
    top: 0;
    left: 15px;
    font-size: 16px;
    padding: 0 2px;
    color: #000;
    background: #Ffff;
}
.container-contact .contact-form  textarea:focus ~ label
{
    top: 0;
    left: 15px;
    font-size: 16px;
    padding: 0 2px;
    color: #000;
    background: #Fff;
}
.contact-submit {
    margin-right: auto;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    background: linear-gradient(180deg, #FF6F48 0%, #FF915A 100%);
    box-shadow: 0px 1px 2px -1px #0000001A,
    0px 1px 3px 0px #0000001A;
    color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 30px;
    border: none;
    outline: none;
    cursor: pointer;
}
.contact-social-container {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.contact-social-container h2 {
    font-family: 'Patrick ', sans-serif;
    font-size: 26px;
    font-weight: 400;
    text-align: center;
    color: #1D1D1F;
    display: flex;
    align-items: center;
    margin: 0 auto;
}
.contact-socials {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 40px;
    width: 100%;
}
.contact-social {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}
.contact-social p {
    text-align: center;
    margin-top: 5px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #1D1D1F;
}
.contact-social h3 {
    text-align: center;
    padding-bottom: 5px;
    border-bottom: 2px solid #CBCBCB;
    font-family: 'Inter', sans-serif;
    font-size: 19px;
    font-weight: 700;
    color: #1D1D1F;
}

@media(max-width: 768px) {
    .container-contact {
        flex-direction: column;
    }
    .container-contact img {
        width: 100%;
    }
    .container-contact .contact-form {
        width: 90%;
    } 
    .container-contact .contact-form h1 {
        font-size: 35px;
    }
    .contact-socials { 
        flex-direction: column;
    }
    .contact-social-container h2 {
        font-size: 16px;
    }
}