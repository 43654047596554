.history {
   background: #F9FAFB;
   min-height: 100vh;
}
.card-container-history {
    width: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;
}
.card-container-history h3 {
    font-weight: 600;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 28px;
    color: #202223;
    text-align: center;
}
.history .card-history {
    display: flex;
    width: 100%;
    padding: 20px;
    box-shadow: 0px 4px 14px 0px #00000017;
    background: #F8FAFC;
    border-radius: 14px;
    margin-top: 10px;
    position: relative;
}
.history .card-history img {
    width: 100px;
    height: 100px;
    border-radius: 14px;
}
.history-content {
    display: flex;
    flex-direction: column;
    padding: 10px;
}
.history-content a {
    margin-top: 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color:#6D7175;
    text-decoration: none;
    font-weight: 400;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
    width: 70%;
}
.card-history span {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #202223;
    font-weight: 400;
    position: absolute;
    top: 20px;
    right: 20px;
}
.sort-product-history {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
}
.selected-option {
    width: 576px;
    height: 36px;
    padding: 6px 8px 6px 12px;
    border-radius: 9px;
    border: 1.23px solid #CCCCCC;
    box-shadow: 0px 4px 14px 0px #00000017;
    font-family: 'Segoe UI', sans-serif;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: #202223;
    cursor: pointer;
}
.custom-dropdown .options {
    width: 100%;
    box-shadow: 0px 2.467825412750244px 2.467825412750244px 0px #0000001A
    , 0px 1.233912706375122px 1.233912706375122px 0px #0000001A;
    background: #fff;
    border-radius: 9px;
}
.custom-dropdown .options li {
    list-style: none;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #202223;
    width: 100%;
    padding: 5px;
    cursor: pointer;
}
.custom-dropdown .options li:hover {
    background: linear-gradient(180deg, #FF6F48 0%, #FF915A 100%);
    color: #F9FAFB;
}

.connect-import .go-bc img {
    width: 10px;
    height: auto;
    margin-right: 4px;
  }
  .connect-import .go-bc {
      text-decoration: none;
      color: #000;
      padding: 5px;
      background: #fff;
      border-radius: 8px;
      box-shadow: 1px 1px 3px 0px #0000001A;
      font-family: 'Helvetica', sans-serif;
      font-size: 9px;
      font-weight: 750;
      text-align: center;
  }
@media(max-width: 768px) {
    .custom-dropdown {
        width: 100%;
    }
    .selected-option {
        width: 100%;
    }
    .card-container-history {
        padding: 10px;
    }
    .history-content a {
        width: 100%;
    }
    .card-history span {
        right: 20px;
        bottom: 10px;
        top: initial;
    }
    .history-content {
        width: 100%;
        overflow: hidden;
    }
}