@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100;9..40,200;9..40,300;9..40,400;9..40,500;9..40,600;9..40,700;9..40,800;9..40,900;9..40,1000&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
 background: linear-gradient(180deg, #493190 0%, #9F4CD8 100%);
}
body.dark, main.dark, body.dark .import {
  background: #030104;
  color: #fff !important;
}
main.dark .container-refund h1, main.dark .container-refund p, main.dark .container-refund li, main.dark .container-terms h1, main.dark .container-terms p, main.dark .container-terms li, main.dark .container-privacy h1, main.dark .container-privacy p,main.dark .container-privacy li  {
  color: #fff !important;
} 
main.dark .contact-social-container h2, main.dark .contact-social-container h3, main.dark .contact-social-container p, main.dark .container-contact .contact-form h1, main.dark .container-contact .contact-form .input-field label {
  color: #fff;
}
main.dark  .container-contact .contact-form{
  background: #000;
  box-shadow: 0px -30.39px 91.16px -26.04px #E2E8FF1F inset !important;
}
main.dark .container-contact .contact-form  textarea:focus ~ label, main.dark .container-contact .contact-form  input:focus ~ label,
.container-contact .contact-form  input:valid ~ label {
  background: #000;
  color: #fff;
}
body.dark .login-form, body.dark .signUp-form {
  background: #000;
  color: #fff;
}
body.dark .login-form h3, body.dark .signUp-form h3, body.dark .login-form p,body.dark .login-form  .forgot, body.dark .active-plan h3 {
  color: #fff;
}
body.dark .active-plan, body.dark .plans-container .cards  {
  background: #000 !important;
}
body.dark .connect-header,  body.dark .history  {
  background: #030104;
}
body.dark .container-connect, body.dark .card-profile {
background: #000;
}
body.dark .container-connect p, body.dark .container-connect h3, body.dark .container-connect li, body.dark .dropdown-options-import  li a, body.dark .import-product label, body.dark .card-container-history h3, body.dark .selected-option  {
  color: #fff;
}
body.dark .dropdown-header-import, body.dark .dropdown-options-import {
  background: #000;
  border: 1px solid orange;
}
.profile .dark-bt {
  margin-left:  auto !important;
  display: block;
}
body.dark .card-profile h1, body.dark .profile-content h3, body.dark .profile-content p {
  color: #fff;
}
body.dark .history .card-history {
  background: #000;
}