.container-form {
    width: 100%;
    min-height: 100vh;
    position: absolute !important;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    z-index: 2000;
    display: none;
    padding-top: 40px;
}
.container-form.active {
    display: flex;
}
.login-form {
    max-width: 600px;
    background: #F8FAFC;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 30px;
    padding: 30px;
}
.login-form h3 {
    font-family: 'Helvetica', sans-serif;
    font-size: 40px;
    font-weight: 750;
    text-align: center;
    color: #13004C;
}
.login-form p {
    font-family: 'Helvetica', sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    margin-top: 15px;
}
.login-form img {
    width: 126px;
    height: 116px;
    margin: 0 auto;
}
.login-form label[for="email"] {
    margin-top: 25px;
}
.login-form label {
    margin-top: 15px;
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    font-weight: 500;
    text-align: left;

}
.login-form input {
    border: 1px solid #0F1519;
    outline: none;
    padding: 10px;
    border-radius: 10px;
    margin-top: 10px;
    background: #FE805026;
}
.login-form .forgot, form .sign-up {
    outline: none;
    color: #13004C;
    background: none;
    border: none;
    cursor: pointer;
}
.p-tag {
    font-family: 'Helvetica', sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
}
.login-form .forgot {
    color: #000;
    margin-top: 15px;
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
}
.login-form .submit {
    background: linear-gradient(180deg, #FF6F48 0%, #FF915A 100%);
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: #fff;
    cursor: pointer;
    outline: none;
    border: none;
    padding: 10px;
    border-radius: 15px;
    margin-top: 10px;
    text-decoration: none;
    z-index: 1200;
}
.trust {
   width: 100% !important;
   height: auto !important;
   margin-top: 10px !important; 
}
.line {
    width: 100%;
    height: 1px;
    background: #00000040;
    margin-top: 15px;
}
.google {
    background: #000 !important;
}
.facebook {
    background: #3E63FF !important;
}
@media(max-width: 576px) {
    .login-form {
        width: 90%;
    }
    .login-form h3 {
        font-size: 25px;
    }
    .login-form p {
        font-size: 12px;
    }
    .login-form label {
        font-size: 12px;
    }
    .login-form button {
        font-size: 12px;
    }
}
