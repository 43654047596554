.connect {
    background: #F9FAFB;
    height: 100%;
}
.connect-header {
    display: flex;
    width: 100%;
    height: 100px;
    align-items: center;
    justify-content: flex-start;
    padding: 50px;
}
.connect-header img {
    width: 200px;
    height: auto;
    margin: 0 auto;
}
.connect-header a img {
  width: 10px;
  height: auto;
  margin-right: 4px;
}
.connect-header a {
    text-decoration: none;
    color: #000;
    padding: 5px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 1px 1px 3px 0px #0000001A;
    font-family: 'Helvetica', sans-serif;
    font-size: 9px;
    font-weight: 750;
    text-align: center;
}
.container-connect {
    width: 600px;
    margin: 0 auto;
    height: auto;
    background: #fff;
    box-shadow: 0px 1px 2px 0px #0000000F,
    0px 1px 3px 0px #0000001A;
    border-radius: 10px;
    padding: 30px;
    position: relative;
    padding-bottom: 140px;
}
.container-connect h3 {
    color: #333;
    text-align: center;
    font-family: 'Segoe UI', sans-serif;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 20px;
}
.container-connect p {
    font-family: 'Segoe UI', sans-serif;
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    color: #202223;
}
.container-connect ul {
    margin-top: 10px;
    width: 100%;
}
.container-connect ul li {
    list-style: none;
    font-family: 'Segoe UI', sans-serif;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: #202223;
    padding-top: 10px;
}
.container-connect ul li b {
    font-weight: 700;
}
.container-connect ul li span {
    padding: 6px;
    background: #fff;
    border-radius: 18px;
    box-shadow: 0px 2px 2px 0px #0000001A, 0px 1px 1px 0px #0000001A;
}
.container-connect .connect-card {
    width: 100%;
    height: 360px;
    border: 1px solid #BBBBBB;
    margin-top: 20px;
    border-radius: 9px;
    margin-bottom: 20px;
}
.card-one {
    background: url('../../image/gif/v1.gif');
    background-size: cover;
    background-position: center;
}
.card-two {
    background: url('../../image/gif/v2.gif');
    background-size: cover;
    background-position: center;
}
.card-three {
    background: url('../../image/gif/v3.gif');
    background-size: cover;
    background-position: center;
}
.card-four {
    background: url('../../image/gif/v4.gif');
    background-size: cover;
    background-position: center;
}
.container-connect .connect-card img {
    width: 100%;
    height: 100%;
}
.container-connect code {
    font-family: 'Consolas', sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    color: #202223;
}
.container-connect .red {
    color: #FF0101;
}
.container-connect .yellow {
    width: 100%;
    padding: 20px;
    background: #FFF68D;
    border-radius: 9px;
}
.container-connect .red-card {
    font-family: 'Segoe UI', sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    background: #E51C01;
    padding: 20px;
    border-radius: 9px;
    margin-top: 10px;
    color: #fff;
}

.connect-form {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    padding: 20px;
    box-shadow: 0px 0.8599249124526978px 1.7198498249053955px 0px #0000000F ,0px 0.8599249124526978px 2.579774856567383px 0px #0000001A;
    border: 1px solid #CCCCCC;
    border-radius: 9px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
}
.connect-form .label-container {
    display: flex;
    flex-direction: column;
    margin: 5px;
    width: 48%;
    position: relative;
    font-family: 'Segoe UI', sans-serif;
    font-size: 11px;
    font-weight: 400;
    text-align: left;

}
.connect-form .label-container i {
    position: absolute;
    top: 25px;
    left: 5px;
}
.connect-form button {
    color: #fff;
    background: linear-gradient(180deg, #FF6F48 0%, #FF915A 100%);
    width: 50%;
    padding: 5px;
    border-radius: 25px;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    border: none;
    text-decoration: none;
}
.connect-form button i {
    position: initial !important;
}
.connect-form input {
    padding: 5px;
    outline: none;
    margin-top: 2px;
    font-family: 'Arial', sans-serif;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    color: #757575;
    padding-left: 25px;
    border: 1px solid #CCCCCC;
}
.connect-form input::placeholder {
    color: #757575;
}

@media(max-width: 768px) {
    .container-connect {
        width: 95%;
    }
    .connect-header img {
        width: 100px;
    }
    .connect-form .label-container {
        width: 100%;
    }
}