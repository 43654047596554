.active-plan {
    max-width: 800px;
    background: #F8FAFC;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 30px;
    padding: 30px;
}
.active-plan h3 {
    font-family: 'Helvetica', sans-serif;
    font-size: 40px;
    font-weight: 750;
    text-align: center;
    color: #13004C;
}
.active-plan p {
    font-family: 'Helvetica', sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    margin-top: 15px;
}
.active-plan a {
    background: linear-gradient(180deg, #FF6F48 0%, #FF915A 100%);
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: #fff;
    cursor: pointer;
    outline: none;
    border: none;
    padding: 10px;
    border-radius: 15px;
    margin-top: 10px;
    text-decoration: none;
    z-index: 1200;
}
@media(max-width: 768px) {
    .active-plan {
        width: 90%;
    }
}