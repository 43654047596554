.import {
    background: #F9FAFB;
    height: 100%;
}

.connect-import {
    display: flex;
    width: 100%;
    height: 100px;
    align-items: center;
    justify-content: flex-start;
    padding: 50px;
}
.connect-import img {
    width: 200px;
    height: auto;
    margin: 0 auto;
}
.container-import-product {
    width: 100%;
    height: calc(100vh - 120px);
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  
  .import-product {
    Width: 358px;
    Height: 279px;
    display: flex;
    flex-direction: column;
  }

  .import-product input, select {
    margin-top: 10px;
    border: none;
    border-radius: 6px;
    padding: 8px;
    background: #fff;
    outline: none;
    box-shadow: 0px 4px 14px 0px #00000017;
    height: 42px;
    font-family: 'DM Sans', sans-serif;
    font-size: 13px;
    font-weight: 500;
  }
  .import-product input::placeholder {
    font-family: 'DM Sans', sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: #0F151940 !important;
  }
  .import-product label {
    margin-top: 10px;
    font-family: 'DM Sans', sans-serif;
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    color: #0F1519CC;
  }
  .select-import {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  .select-import button:nth-child(2) {
    margin-left: 5px;
  }
  .select-import button {
    font-family: 'Helvetica', sans-serif;
    font-size: 8px;
    font-weight: 750;
    text-align: center;
    color: #000;
    background: #fff;
    cursor: pointer;
    outline: none;
    border: none;
    width: 90px;
    box-shadow: 0px 4px 14px 0px #00000017;
    padding: 10px;
    border-radius: 6px;
  }
  .select-import button.active {
    background: linear-gradient(180deg, #FF6F48 0%, #FF915A 100%);
    color: #fff;
  }
  
  .import-product .import {
    width: 30%;
    background: linear-gradient(180deg, #FF6F48 0%, #FF915A 100%);
    color: #fff;
    margin: 10px auto;
    border: none;
    box-shadow: 0px 4px 14px 0px #00000017;
    padding: 10px;
    border-radius: 6px;
    cursor: pointer;
    outline: none;
    font-family: 'Helvetica', sans-serif;
    font-size: 10px;
    font-weight: 750;
}
.import-product .import i {
  margin-right: 5px;
}
.go-bck {
  text-decoration: none;
  color: #000;
  padding: 10px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 1px 1px 3px 0px #0000001A;
  min-width: 100px;
  margin: 0 auto;
  text-align: center;
  font-family: 'Helvetica', sans-serif;
  font-size: 10px;
  font-weight: 750;
  text-align: center;
}
.go-bck img {
  margin-right: 5px;
}
[data-tooltip] {
  position: relative;
  cursor: pointer;
  margin-left: 5px;
}
[data-tooltip]::before,
[data-tooltip]::after {
  text-transform: none;
  line-height: 1;
  position: absolute;
  display: none;
  opacity: 0;
}
[data-tooltip]::before {
  content: '';
  border: 6px solid transparent;
  z-index: 101;
}
[data-tooltip]::after {
  content: attr(data-tooltip);
  min-width: 140px;
  padding: 6px 8px;
  border-radius: 3px;
  background: #333333;
  color: #FFFFFF;
  z-index: 100;
  font-size: 10px;
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  text-align: left;
}
[data-tooltip]:hover::before,
[data-tooltip]:hover::after {
  display: block;
}
[data-tooltip='']::before,
[data-tooltip='']::after {
  display: none !important;
}
[data-tooltip]:not([data-flow])::before,
[data-tooltip][data-flow^="top"]::before {
  bottom: 100%;
  border-bottom-width: 0;
  border-top-color: #333333;
}
[data-tooltip]:not([data-flow])::after,
[data-tooltip][data-flow^="top"]::after {
  bottom: calc(100% + 5px);
}
[data-tooltip]:not([data-flow])::before,
[data-tooltip]:not([data-flow])::after,
[data-tooltip][data-flow^="top"]::before,
[data-tooltip][data-flow^="top"]::after {
  left: 50%;
  transform: translate(-50%, -.4em);
}
[data-tooltip][data-flow^="bottom"]::before {
  top: 100%;
  border-top-width: 0;
  border-bottom-color: #333333;
}
[data-tooltip][data-flow^="bottom"]::after {
  top: calc(100% + 5px);
}
[data-tooltip][data-flow^="bottom"]::before,
[data-tooltip][data-flow^="bottom"]::after {
  left: 50%;
  transform: translate(-50%, .4em);
}
[data-tooltip][data-flow^="left"]::before {
  top: 50%;
  border-right-width: 0;
  border-left-color: #333333;
  left: calc(0em - 5px);
  transform: translate(-.5em, -50%);
}
[data-tooltip][data-flow^="left"]::after {
  top: 50%;
  right: calc(100% + 5px);
  transform: translate(-.4em, -50%);
}
[data-tooltip][data-flow^="right"]::before {
  top: 50%;
  border-left-width: 0;
  border-right-color: #333333;
  right: calc(0em - 7px);
  transform: translate(.4em, -50%);
}
[data-tooltip][data-flow^="right"]::after {
  top: 50%;
  left: calc(100% + 5px);
  transform: translate(.5em, -50%);
}
@keyframes tooltip-vert {
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}
@keyframes tooltip-horz {
  to {
    opacity: 1;
    transform: translate(0, -50%);
  }
}
[data-tooltip]:not([data-flow]):hover::before,
[data-tooltip]:not([data-flow]):hover::after,
[data-tooltip][data-flow^="top"]:hover::before,
[data-tooltip][data-flow^="top"]:hover::after,
[data-tooltip][data-flow^="bottom"]:hover::before,
[data-tooltip][data-flow^="bottom"]:hover::after {
  animation: tooltip-vert .5s ease-out forwards;
}
[data-tooltip][data-flow^="left"]:hover::before,
[data-tooltip][data-flow^="left"]:hover::after,
[data-tooltip][data-flow^="right"]:hover::before,
[data-tooltip][data-flow^="right"]:hover::after {
  animation: tooltip-horz .5s ease-out forwards;
}


@media(max-width: 768px) {
    .connect-import {
        padding: 0;
        flex-direction: column;
        height: auto;
        padding-top: 20px;
    }
    .import-product {
        width: 90%;
    }
    .connect-import img {
      width: 100px;
    }
}